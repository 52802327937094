import { connect } from 'react-redux';
import SignInForm from '../components/Auth/SignInForm';
import { configure2fa, signin } from '../actions/SigninActions';
import { verify2fa } from '../actions/SigninActions';

const mapStateToProps = (state) => {
  return {
    signin: state.auth.signin,
    signInLoader: state.auth.signInLoader,
    validationError: state.auth.validationError,
    queryParam: state.router.location.query,
    authId: state.auth.authId,
    required2fa: state.auth.required2fa,
    setup2fa: state.auth.setup2fa,
    userEmail: state.auth.userEmail,
    setup2faKey: state.auth.setup2faKey,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSignIn: (value) => dispatch(signin(value)),
    verify2fa: (user_id, code) => dispatch(verify2fa(user_id, code)),
    configure2Fa: (key) => dispatch(configure2fa(key)),
  };
};

const SignIn = connect(mapStateToProps, mapDispatchToProps)(SignInForm);

export default SignIn;
