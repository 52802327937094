import ErrorMessage from '../../utils/ErrorMessage';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getCookie, validateFormFields } from '../../utils/helper';

const PersonalInfo = ({
  billingInfo,
  setBillingInfo,
  billingInfoErrors,
  profile,
  cartData,
  recaptureTrack,
  checkValidation,
  getFieldRules,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (cartData && cartData.details && cartData.details.length) {
      if (billingInfo && (billingInfo.edd_email || billingInfo.edd_first)) {
        setIsLoggedIn(true);
        updateRecaptureCart();
      }
    }
  }, []);

  const findUsOptions = [
    { value: 'wordpress', label: 'WordPress.org' },
    { value: 'free-plugin', label: 'Free Plugin' },
    { value: 'google', label: 'Google Search' },
    { value: 'facebook-group', label: 'Facebook Group' },
    { value: 'email-newsletter', label: 'Email Newsletter' },
    { value: 'advertisement', label: 'Advertisement' },
    { value: 'from-friend', label: 'From Friend' },
    { value: 'online-discussion', label: 'Online Discussion' },
    { value: 'blog-article', label: 'Blog/Article' },
    { value: 'other', label: 'Other' },
  ];

  const getSelectedAcquisitionMethod = (value) =>
    findUsOptions.filter((option) => option.value === value);

  const handleEmailFillUp = (e) => {
    const validated = validateFormFields(
      { email: e.target.value },
      {
        email: {
          required: true,
          email: true,
        },
      }
    );

    if (!validated.valid) {
      return;
    }
    updateRecaptureCart();
  };

  const updateRecaptureCart = () => {
    const cartKey = getCookie('racart');
    const customer = getCookie('ra_customer_id');

    if (cartKey && customer && cartData && cartData.details) {
      let checkoutUrl = process.env.REACT_APP_BASE_URL;
      const content = btoa(
        JSON.stringify(
          cartData.details.map(
            (item) =>
              item.id + '|' + item.item_number.options.price_id + '|' + 1
          )
        )
      )
        .replaceAll('+', '.')
        .replaceAll('/', '_')
        .replaceAll('=', '-');

      const cart = {
        external_id: cartKey,
        grand_total: cartData.total,
        products: cartData.details.map((item) => ({
          name: item.name + ' - ' + item.item_number.price_name,
          image: item.item_number.image,
          price: item.price,
          external_id: item.id,
          qty: 1,
          variant_id: item.item_number.options.price_id,
        })),
        discount_codes: cartData.discounts,
        checkout_url: `${checkoutUrl}?racart=${cartKey}&contents=${content}`,
        customer: customer,
      };
      if (billingInfo) {
        if (billingInfo.edd_first && billingInfo.edd_first.length) {
          cart.first_name = billingInfo.edd_first;
        }
        if (billingInfo.edd_last && billingInfo.edd_last.length) {
          cart.last_name = billingInfo.edd_last;
        }
        if (billingInfo.edd_email && billingInfo.edd_email.length) {
          cart.email = billingInfo.edd_email;
        }
      }
      recaptureTrack(cart);
    }
  };
  const handleInputChange = (e, field) => {
    if (!isLoggedIn) {
      let value = '';
      if (field.toString() === 'edd_acquisition_method') {
        value = e.value;
      } else {
        value = e.target.value;
      }
      setBillingInfo({ ...billingInfo, [field]: value });
      checkValidation(
        {
          [field]: value,
        },
        false
      );
    }
  };
  return (
    <section className="WpdPaymentMethodSection">
      <h4 className="WpdPaymentMethodSectionTitle">Personal Information</h4>
      <div className="WpdFormWrapper">
        <div className="row">
          <div className="col-sm-6">
            <div className="WpdFormGroup WpdHasIcon">
              <input
                type="text"
                className={`form-control ${
                  billingInfoErrors.edd_first &&
                  billingInfoErrors.edd_first.length &&
                  'WpdHasError'
                }`}
                readOnly={isLoggedIn}
                placeholder="First Name*"
                value={billingInfo.edd_first}
                onChange={(e) => handleInputChange(e, 'edd_first')}
                onBlur={updateRecaptureCart}
              />
              <span className="WpdFormIcon">
                <i className="wpd-icon wpd-user2"></i>
              </span>
            </div>
            <ErrorMessage error={billingInfoErrors.edd_first} />
          </div>
          <div className="col-sm-6">
            <div className="WpdFormGroup WpdHasIcon">
              <input
                type="text"
                className={`form-control ${
                  billingInfoErrors.edd_last &&
                  billingInfoErrors.edd_last.length &&
                  'WpdHasError'
                }`}
                readOnly={isLoggedIn}
                placeholder="Last Name*"
                value={billingInfo.edd_last}
                onChange={(e) => handleInputChange(e, 'edd_last')}
                onBlur={updateRecaptureCart}
              />
              <span className="WpdFormIcon">
                <i className="wpd-icon wpd-user2"></i>
              </span>
            </div>
            <ErrorMessage error={billingInfoErrors.edd_last} />
          </div>
          <div className="col-sm-6">
            <div className="WpdFormGroup WpdHasIcon">
              <input
                type="email"
                className={`form-control ${
                  billingInfoErrors.edd_email &&
                  billingInfoErrors.edd_email.length &&
                  'WpdHasError'
                }`}
                readOnly={isLoggedIn}
                placeholder="Email Address*"
                value={billingInfo.edd_email}
                onChange={(e) => handleInputChange(e, 'edd_email')}
                onBlur={handleEmailFillUp}
              />
              <span className="WpdFormIcon">
                <i className="wpd-icon wpd-mail"></i>
              </span>
            </div>
            <ErrorMessage error={billingInfoErrors.edd_email} />
          </div>
          <div className="col-sm-6">
            <div className="WpdFormGroup WpdHasIcon">
              <Select
                value={
                  billingInfo.edd_acquisition_method
                    ? getSelectedAcquisitionMethod(
                        billingInfo.edd_acquisition_method
                      )
                    : undefined
                }
                options={findUsOptions}
                onChange={(selected) =>
                  handleInputChange(selected, 'edd_acquisition_method')
                }
                isSearchable={false}
                classNamePrefix="WpdCheckoutSelect"
                placeholder={'How did you find us?*'}
              />
            </div>
            <ErrorMessage error={billingInfoErrors.edd_acquisition_method} />
          </div>
          {!profile && (
            <div className="col-sm-12">
              <p className="WpdSignInMessage my-3">
                Already have an account? <a href="/auth/sign-in">Sign In</a>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PersonalInfo;