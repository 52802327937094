import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_VALIDATION_FAILED,
  SIGN_IN,
  ACTIVE_COMPANY_NOT_FOUND,
  SET_AUTO_LOGGED_IN_USER,
  VERIFY_2FA,
  REQUIRED_2FA,
  SETUP_2FA,
  CONFIGURE_2FA,
  CONFIGURE_2FA_SUCCESS,
  VERIFY_2FA_FAILED,
  CONFIGURE_2FA_FAILED,
} from '../actions/SigninActions';

import {
  ACCOUNT_SWITCH_SUCCESS,
  RESET_SIGIN_REDIRECT,
  RESET_ACCOUNT_SWITCH_SUCCESS,
  ACCOUNT_SWITCH_FAILED,
} from '../actions/HeaderActions';

import {
  SIGNED_OUT,
  ACCESS_REMOVED,
  RESET_ACCESS_REMOVED,
  RESET_COMPANY_NOT_FOUND,
  TOKEN_ERROR,
} from '../actions/index';

import { toast } from 'react-toastify';
import {
  clearSwitchedUserLocalData,
  clearUserLocalStorage,
  isLoggedIn,
  updateSwitchedBackToUserData,
} from '../utils/helper';
import { SAVE_PROFILE_SUCCESS } from '../actions/ProfileAction';

const initialState = {
  signin: undefined,
  validationError: [],
  accountSwitchSuccess: false,
  signInLoader: false,
  createCompanyLoader: false,
  accessRemoved: false,
  companyNotFound: false,
  signout: false,
  role_permission_info: [],
  authId: undefined,
  required2fa: false,
  setup2fa: false,
  has2faError: undefined,
  userEmail: '',
  setup2faKey: '',
};

export default function signIn(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        signInLoader: true,
        required2fa: false,
        setup2fa: false,
      };

    case SIGN_IN_SUCCESS:
      clearUserLocalStorage();
      localStorage.setItem('access_token', action.credentials.nonce);
      localStorage.setItem('display_name', action.credentials.display_name);
      localStorage.setItem('first_name', action.credentials.first_name);
      localStorage.setItem('last_name', action.credentials.last_name);
      localStorage.setItem('profile_image', action.credentials.gravatar);
      localStorage.setItem('user_type', action.credentials.user_type);

      let switched_user = undefined;

      if (action?.credentials?.switch_back_to) {
        switched_user = {
          id: action.credentials.switch_back_to.user_id,
          name: action.credentials.switch_back_to.display_name,
          first_name: action.credentials.switch_back_to.first_name,
          last_name: action.credentials.switch_back_to.last_name,
        };

        clearSwitchedUserLocalData();
        updateSwitchedBackToUserData(switched_user);
      }

      let signin = getUserInfo(action.credentials);
      if (switched_user !== undefined) {
        signin = { ...signin, switched_user: switched_user };
      }

      return {
        ...state,
        signin: signin,
        signout: false,
        required2fa: false,
        setup2fa: false,
        has2faError: undefined,
      };

    case SIGN_IN_FAILURE:
      clearUserLocalStorage();
      toast.error(action.message);
      return { ...state, signInLoader: false, signin: undefined };
    case SIGN_IN_VALIDATION_FAILED:
      clearUserLocalStorage();
      toast.error(action.message);
      return { ...state, signInLoader: false, validationError: action.errors };

    case ACCOUNT_SWITCH_SUCCESS:
      toast.success(action.message);
      const signInData = {
        ...state.signin,
        activeProfile: action.activeProfiles,
        allProfile: action.allProfile,
        accountType: action.loggedInAccountType,
        accountSwitchSuccess: true,
        role_permission_info: action.role_permission,
      };

      return { ...state, signin: signInData, accountSwitchSuccess: true };
    case ACCESS_REMOVED:
      const signInData_01 = {
        ...state.signin,
        activeProfile: action.data.activeProfile,
        allProfile: action.data.allProfiles,
        accountType: action.data.loggedInAccountType,
      };
      return {
        ...state,
        signin: signInData_01,
        accessRemoved: true,
      };
    case RESET_ACCESS_REMOVED:
      return {
        ...state,
        accessRemoved: false,
      };
    case RESET_COMPANY_NOT_FOUND:
      return {
        ...state,
        companyNotFound: false,
      };
    case ACCOUNT_SWITCH_FAILED:
      toast.error(action.message);
      return { ...state, accountSwitchSuccess: false };

    case RESET_SIGIN_REDIRECT:
      return { ...state, accountSwitchSuccess: false };
    case RESET_ACCOUNT_SWITCH_SUCCESS:
      return { ...state, accountSwitchSuccess: false };
    case ACTIVE_COMPANY_NOT_FOUND:
      localStorage.getItem('access_token') && toast.error(action.message);

      clearUserLocalStorage();

      const credentials_03 = {
        redirect: false,
        signInLoader: false,
        redirect_to: 'company-create-form',
        accountType: action.data.loggedInAccountType,
        token: action.data.token,
      };
      return { ...state, signin: credentials_03, companyNotFound: true };

    case TOKEN_ERROR:
      return {
        ...state,
        signInLoader: false,
        signin: undefined,
        signout: true,
      };
    case SAVE_PROFILE_SUCCESS:
      localStorage.setItem('display_name', action.response.display_name);
      localStorage.setItem('first_name', action.response.first_name);
      localStorage.setItem('last_name', action.response.last_name);
      localStorage.setItem('profile_image', action.response.gravatar);
      return {
        ...state,
        signin: {
          ...state.signin,
          userInfo: {
            ...state.signin.userInfo,
            first_name: action.response.first_name,
            last_name: action.response.last_name,
            display_name: action.response.display_name,
            email: action.response.user_email,
            profile_image: action.response.gravatar,
            name: action.response.name,
          },
        },
      };
    case SET_AUTO_LOGGED_IN_USER:
      localStorage.setItem('display_name', action.user.display_name);
      localStorage.setItem('first_name', action.user.first_name);
      localStorage.setItem('last_name', action.user.last_name);
      localStorage.setItem('profile_image', action.user.gravatar);
      return {
        ...state,
        signin: {
          ...state.signin,
          userInfo: {
            ...action.user,
          },
        },
      };
    case SIGNED_OUT:
      clearUserLocalStorage();
      clearSwitchedUserLocalData();
      return { ...initialState };
    case REQUIRED_2FA:
      toast.warn(action.data.message);
      return {
        ...initialState,
        authId: action.data.auth_id,
        required2fa: action.data.required_2fa,
        signInLoader: false,
      };
    case SETUP_2FA:
      return {
        ...state,
        authId: action.data.auth_id,
        setup2fa: action.data.setup_2fa,
        signInLoader: false,
        userEmail: action.data.user_email ?? undefined,
        setup2faKey: action.data.setup2faKey ?? '',
      };
    case CONFIGURE_2FA:
      return {
        ...state,
        signInLoader: true,
      };
    case CONFIGURE_2FA_SUCCESS:
      return {
        ...state,
        signInLoader: false,
        required2fa: true,
        setup2fa: false,
        setup2faKey: action.data.setup2faKey ?? '',
      };
    case CONFIGURE_2FA_FAILED:
      toast.error(action.data.message);
      return {
        ...state,
        signInLoader: false,
      };
    case VERIFY_2FA:
      return {
        ...state,
        signInLoader: true,
      };
    case VERIFY_2FA_FAILED:
      if (isLoggedIn()) {
        return {
          ...state,
          signInLoader: false,
          has2faError:
            'Sorry, your verification code is not valid, please try again.',
        };
      }
      clearUserLocalStorage();
      toast.error(
        'Sorry, your verification code is not valid, please try again.'
      );
      return { ...state, signInLoader: false, signin: undefined };
    default:
      return state;
  }
}

const getUserInfo = (data) => {
  return {
    redirect: true,
    signInLoader: false,
    token: data.nonce,
    is_customer: data.is_customer,
    user_type: data.user_type,
    userInfo: {
      user_status: data.status,
      first_name: data.first_name,
      last_name: data.last_name,
      display_name: data.display_name,
      email: data.email,
      user_type: 'ADMIN',
      profile_image: data.gravatar,
      name: data.name,
    },
  };
};
