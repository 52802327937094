import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import ErrorMessage from '../../utils/ErrorMessage';

import WpiLogo from '../../Markup/assets/img/WpdLogoWithText.svg';
import Banner from '../../Markup/assets/img/login.svg';
import { maskEmail } from '../../utils/helper';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
/* eslint-enable */

const SignInForm = ({
  userSignIn,
  signin,
  history,
  signInLoader,
  validationError,
  queryParam,
  authId,
  required2fa,
  verify2fa,
  setup2fa,
  configure2Fa,
  userEmail,
  setup2faKey,
}) => {
  let email;
  let password;

  const [err, setErr] = useState({
    email: [],
    password: [],
    code: '',
  });
  const [view, setView] = useState('signin');
  const [code, setCode] = useState('');
  const [configureMail, setConfigureMail] = useState('');

  /* eslint-disable */
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token && signin && signin.redirect) {
      //email.value = '';
      //password.value = '';
      if (queryParam && queryParam.redirect) {
        history.push(`/${queryParam.redirect}`);
      } else if (signin.accountType === 'SUPER_ADMIN') {
        history.push('/user/admin_user/dashboard');
      } else if (signin.accountType !== 'SUPER_ADMIN') {
        history.push('/user/dashboard');
      }
    }

    if (token && signin && !signin.redirect) {
      email.value = '';
      password.value = '';
      signin.accountType !== 'SUPER_ADMIN' &&
        signin.redirect_to === 'company-create-form' &&
        history.push('/auth/create-company-form');
    }
  }, [signin, history]);

  useEffect(() => {
    if (required2fa) {
      setView('2faForm');
    }
  }, [required2fa]);

  useEffect(() => {
    if (setup2fa) {
      setView('configure2fa');
    }
  }, [setup2fa]);

  useEffect(() => {
    if (userEmail) {
      setConfigureMail(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (validationError.length > 0) {
      let emailErr = [];
      let passwordErr = [];
      validationError[0].map((err) => {
        if (err.param === 'email') {
          emailErr.push(err.msg);
        }
        if (err.param === 'password') {
          passwordErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        email: emailErr,
        password: passwordErr,
      });
    } else {
      setErr({
        email: [],
        password: [],
      });
    }
  }, [validationError]);

  /* eslint-enable */

  const validateForm = () => {
    const errors = [];

    let emailErr = [];
    let passwordErr = [];

    if (email.value === '') {
      emailErr.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email.value)) {
      errors.push('Email is not valid');
      emailErr.push('Email is not valid');
    }

    if (password.value === '') {
      passwordErr.push("Password can't be empty");
      errors.push("Password can't be empty");
    }

    if (errors.length > 0) {
      setErr({
        email: emailErr,
        password: passwordErr,
      });
    }
    return errors;
  };

  const submitSignInForm = (e) => {
    e.preventDefault();
    var errors = validateForm();
    if (errors.length > 0) {
      return;
    }
    setConfigureMail(email.value);
    userSignIn({
      username: email.value,
      password: password.value,
    });
  };

  const submit2FaForm = (e) => {
    e.preventDefault();
    if (!code.length) {
      setErr({ ...err, code: 'Please insert verification code' });
      return;
    } else {
      setErr({ ...err, code: '' });
    }
    if (authId) {
      verify2fa(authId, code);
    }
  };

  const submit2FaConfigureForm = (e) => {
    e.preventDefault();
    configure2Fa(setup2faKey);
  };

  return (
    <div className="WpdPageBody WpdLoginBody">
      <div className="WpdLoginHeader">
        <Link to={'/auth/sign-in'} className="WpdSiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="WpdLoginWrapper">
        <div className="WpdLoginContent">
          <div className="WpdLoginForm">
            {view === 'configure2fa' ? (
              <>
                <button
                  onClick={(e) => setView('signin')}
                  className="WpdBackButton mb-4"
                >
                  <div className="WpdIcon">
                    <i className="wpd-icon wpd-angle-left"></i>
                  </div>
                  <div className="WpdText">Back to Login</div>
                </button>
                <Form onSubmit={submit2FaConfigureForm}>
                  <h2 style={{ marginBottom: 10 }}>Configure 2FA</h2>
                  <p className="mb-3">
                    To complete the 2FA Authentication, you will be sent OTP
                    over your email address : <strong>{configureMail}</strong>
                  </p>
                  <p className="mb-3">
                    <strong>Note:</strong> To enhance your account security and
                    provide you with peace of mind, it is mandatory to configure
                    Two-Factor Authentication (2FA) immediately. Take a moment
                    to set up 2FA now to enjoy a worry-free experience while
                    using our products.
                  </p>
                  {/*<div className="WpdFormGroup mb-4">
                    <input
                      type="text"
                      className={`form-control`}
                      value={configureMail}
                      disabled
                    />
                    {err && err.code && <ErrorMessage error={err.code} />}
                  </div>*/}
                  <button
                    type="submit"
                    className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
                    disabled={signInLoader}
                  >
                    <span className="WpdButtonInner">
                      <span className="WpdText">
                        {signInLoader ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{ height: 26, width: 26 }}
                          />
                        ) : (
                          'Configure 2FA Now'
                        )}
                      </span>
                    </span>
                  </button>
                </Form>
              </>
            ) : view === '2faForm' ? (
              <>
                <button
                  onClick={(e) => setView('signin')}
                  className="WpdBackButton mb-4"
                >
                  <div className="WpdIcon">
                    <i className="wpd-icon wpd-angle-left"></i>
                  </div>
                  <div className="WpdText">Back to Login</div>
                </button>
                <Form onSubmit={submit2FaForm}>
                  <h2 style={{ marginBottom: 10 }}>
                    Verify 2FA Authentication
                  </h2>
                  <p style={{ marginBottom: 20 }}>
                    Enter OTP that has been sent over your email address:{' '}
                    {maskEmail(configureMail)}
                  </p>
                  <div className="WpdFormGroup mb-4">
                    <label className="WpdFormLabel">Enter OTP</label>
                    <input
                      type="text"
                      className={`form-control ${
                        err.code && err.code.length && 'WpdHasError'
                      }`}
                      placeholder="Place your Verification Code here"
                      autoComplete={'off'}
                      onChange={(e) => setCode(e.target.value)}
                      defaultValue={''}
                    />
                    {err && err.code && <ErrorMessage error={err.code} />}
                  </div>
                  <p className="mb-3">
                    <strong>IMPORTANT:</strong> If you do not receive the email
                    with the OTP, please check your Spam folder or contact{' '}
                    <a
                      href="https://wpdeveloper.com/support/"
                      target="_blank"
                      className="text-primary"
                    >
                      support
                    </a>
                    .
                  </p>
                  <button
                    type="submit"
                    className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
                  >
                    <span className="WpdButtonInner">
                      <span className="WpdText">
                        {signInLoader ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{ height: 26, width: 26 }}
                          />
                        ) : (
                          'Verify Authentication'
                        )}
                      </span>
                    </span>
                  </button>
                </Form>
              </>
            ) : (
              <Form onSubmit={submitSignInForm}>
                <h2>Login</h2>
                <div className="WpdFormGroup mb-4">
                  <label className="WpdFormLabel">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    className={`form-control ${
                      err.email && err.email.length && 'WpdHasError'
                    }`}
                    placeholder="Example@example.com"
                    autoFocus={true}
                    ref={(node) => {
                      email = node;
                    }}
                  />
                  {err &&
                    err.email &&
                    err.email.map((err, index) => <ErrorMessage error={err} />)}
                </div>
                <div className="WpdFormGroup mb-4">
                  <label className="WpdFormLabel">Password</label>
                  <input
                    type="password"
                    id="password"
                    className={`form-control ${
                      err.password && err.password.length && 'WpdHasError'
                    }`}
                    placeholder="********"
                    ref={(node) => {
                      password = node;
                    }}
                  />
                  {err &&
                    err.password &&
                    err.password.map((err, index) => (
                      <ErrorMessage error={err} />
                    ))}
                </div>
                <button
                  type="submit"
                  className="WpdButton WpdInfoButton WpdFilled w-100 mt-2"
                >
                  <span className="WpdButtonInner">
                    <span className="WpdText">
                      {signInLoader ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          style={{ height: 26, width: 26 }}
                        />
                      ) : (
                        'Login'
                      )}
                    </span>
                  </span>
                </button>
                <div className="d-flex mt-4">
                  <Link to="/auth/forget-pass" className="WpdFont500">
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            )}
          </div>
        </div>
        <div className="WpdLoginImage">
          <img src={Banner} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

SignInForm.prototypes = {
  signin: PropTypes.shape({
    redirect: PropTypes.bool,
    token: PropTypes.string,
  }),
};

export default SignInForm;
