export const agencyYearly = {
  downloadId: 474227,
  priceId: 1,
  price: 349,
  name: 'Agency Bundle Yearly',
  details: function (cartTotal, cartDetails) {
    return `Unlock Agency Bundle Yearly License & get access to 10+ Premium Products for only <b>${getRequiredPrice(
      cartTotal,
      this.price
    )}</b>.`;
  },
  savedValue: 2500,
};
export const agencyLifetime = {
  downloadId: 474227,
  priceId: 2,
  price: 799,
  name: 'Agency Bundle Lifetime',
  details: function (cartTotal, cartDetails) {
    if (cartTotal === 0) {
      return `Grab our Agency Bundle Lifetime pack only for <b>${getRequiredPrice(
        cartTotal,
        this.price
      )}</b> and unlock LIFETIME access to 10 advanced WordPress PRO plugins on unlimited websites!`;
    }
    return `Paying ${cartTotal} for ${
      cartDetails?.contents?.length
    } product? Grab our Agency Bundle Lifetime pack instead by adding only <b>${getRequiredPrice(
      cartTotal,
      this.price
    )}</b> and unlock LIFETIME access to 10 advanced WordPress PRO plugins on unlimited websites!`;
  },
  savedValue: 6000,
  coupon: '',
};
export const upsellProducts = [
  {
    downloadId: 4372,
    upsells: [
      {
        priceId: 1,
        price: 49.97,
        products: [
          {
            downloadId: 4372,
            priceId: 3,
            price: 99.97,
            name: 'Essential Addons Unlimited',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 3,
        price: 99.97,
        products: [
          {
            downloadId: 4372,
            priceId: 4,
            price: 249.97,
            name: 'Essential Addons Lifetime Unlimited',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b> .`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 4,
        price: 249.97,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 342422,
    upsells: [
      {
        priceId: 1,
        price: 69,
        products: [
          {
            downloadId: 342422,
            priceId: 2,
            price: 149,
            name: 'BetterDocs Innovator',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 2,
        price: 149,
        products: [
          {
            downloadId: 342422,
            priceId: 3,
            price: 299,
            name: 'BetterDocs Visionary',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b> more`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 299,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 99658,
    upsells: [
      {
        priceId: 1,
        price: 49,
        products: [
          {
            downloadId: 99658,
            priceId: 3,
            price: 199,
            name: 'NotificationX Business',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 3,
        price: 199,
        products: [
          {
            downloadId: 99658,
            priceId: 4,
            price: 399,
            name: 'NotificationX Lifetime Unlimited',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 4,
        price: 399,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 414494,
    upsells: [
      {
        priceId: 1,
        price: 49,
        products: [
          {
            downloadId: 414494,
            priceId: 2,
            price: 149,
            name: 'EmbedPress Business',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            savedValue: '',
            coupon: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 2,
        price: 149,
        products: [
          {
            downloadId: 414494,
            priceId: 3,
            name: 'EmbedPress Lifetime Unlimited',
            price: 299,
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 299,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 78593,
    upsells: [
      {
        priceId: 1,
        price: 49,
        products: [
          {
            downloadId: 78593,
            priceId: 3,
            price: 149,
            name: 'SchedulePress Business',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 3,
        price: 149,
        products: [
          {
            downloadId: 78593,
            priceId: 4,
            price: 399,
            name: 'SchedulePress Lifetime Unlimited',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only  <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 4,
        price: 399,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 764539,
    upsells: [
      {
        priceId: 1,
        price: 99,
        products: [
          {
            downloadId: 764539,
            priceId: 2,
            price: 249,
            name: 'BetterLinks Business',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 2,
        price: 249,
        products: [
          {
            downloadId: 764539,
            priceId: 3,
            price: 399,
            name: 'BetterLinks Agency Lifetime',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 399,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 462493,
    upsells: [
      {
        priceId: 1,
        price: 69,
        products: [
          {
            downloadId: 462493,
            priceId: 2,
            price: 149,
            name: 'ReviewX Small Business',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 2,
        price: 149,
        products: [
          {
            downloadId: 462493,
            priceId: 3,
            price: 299,
            name: 'ReviewX Agency Lifetime',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 299,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 474227,
    upsells: [
      {
        priceId: 1,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 9791,
    upsells: [
      {
        priceId: 1,
        price: 49,
        products: [
          {
            downloadId: 9791,
            priceId: 3,
            price: 249,
            name: 'Fleixa PRO Lifetime',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 249,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
  {
    downloadId: 1677666,
    upsells: [
      {
        priceId: 1,
        price: 49,
        products: [
          {
            downloadId: 1677666,
            priceId: 2,
            price: 149,
            name: 'Essential Blocks Innovator',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b>.`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyYearly,
          },
        ],
      },
      {
        priceId: 2,
        price: 149,
        products: [
          {
            downloadId: 1677666,
            priceId: 3,
            price: 349,
            name: 'Essential Blocks Visionary',
            details: function (cartTotal, cartDetails) {
              return `Get access to Unlimited Sites License and Lifetime Updates & Support for only <b>${getRequiredPrice(
                cartTotal,
                this.price
              )}</b> .`;
            },
            coupon: '',
            savedValue: '',
          },
          {
            ...agencyLifetime,
          },
        ],
      },
      {
        priceId: 3,
        price: 349,
        products: [
          {
            ...agencyLifetime,
          },
        ],
      },
    ],
  },
];

function getRequiredPrice(cartTotal, price) {
  if (cartTotal > price || parseInt(cartTotal) === 0) {
    return `$${price}`;
  }
  return `$${(price - cartTotal).toFixed(2)} more`;
}
